import {
    Box,
    Button,
    createStyles,
    CssBaseline,
    Grid,
    Link as MuiLink,
    makeStyles,
    MuiThemeProvider,
    Theme,
    Typography
} from '@material-ui/core';
import {createTheme} from '@material-ui/core/styles';
import ExpandDownIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import React, {useEffect, useLayoutEffect} from 'react';
import ReactGA from 'react-ga';
import {BrowserRouter as Router, Route, Switch, useLocation} from "react-router-dom";
import {careers} from './Careers';
import ClearFlaskLogo from './ClearFlaskLogo';

const theme = createTheme({
    palette: {primary: {main: '#939363'}}
});

const useStyles = makeStyles((theme: Theme) => createStyles({
    smotanaBrandName: {
        fontFamily: "'Raleway', sans-serif",
        fontWeight: 100,
        color: "#939363ff",
    },
    app: {
        fontFamily: '"Hind", sans-serif',
        textAlign: "center",
        margin: "auto",
        padding: 10,
        maxWidth: 1024,
    },
    imgFit: {
        maxWidth: "100%",
        maxHeight: "100%",
        display: "inline-block",
    },
    imgProjectContainer: {},
    imgProject: {
        margin: 15,
        borderRadius: 15,
        width: 'auto',
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    contentProject: {
        maxWidth: 200,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            margin: 'auto',
        }
    },
    centerVerticalContainer: {
        minHeight: 768,
        position: 'relative',
    },
    projectHeading: {
        margin: theme.spacing(2),
    },
    expandDownIcon: {
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        opacity: 0.1,
        fontSize: '10em',
    },
    centerVerticalInner: {
        position: 'relative',
        top: '50%',
        webkitTransform: 'translateY(-50%)',
        msTransform: 'translateY(-50%)',
        transform: 'translateY(-50%)',
    },
    link: {
        color: 'inherit',
        textDecoration: 'none!important',
        borderBottom: '1px dashed #d0d0d0',
        '&:hover': {
            borderBottomStyle: 'solid',
            borderColor: 'black',
        },
    },
}));
export default () => {
    const classes = useStyles();

    useLayoutEffect(() => {
        ReactGA.initialize('UA-127162051-1', {
            gaOptions: {}
        });
        ReactGA.set({
            anonymizeIp: true,
            forceSSL: true
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <MuiThemeProvider theme={theme}>
            <Router>
                <ScrollToTop/>
                <div className={classes.app}>
                    <CssBaseline/>
                    <Route path='/' render={({location}) => {
                        ReactGA.set({page: location.pathname + location.search});
                        ReactGA.pageview(location.pathname + location.search);
                        return null;
                    }}/>
                    <Switch>
                        <Route exact path="/">
                            <LandingHeader/>
                        </Route>
                        <Route path="*">
                            <Header/>
                        </Route>
                    </Switch>
                    <Switch>
                        {careers.map(career => (
                            <Route exact path={`/careers/${career.slug}`}>{career.render()}</Route>
                        ))}
                        <Route exact path="/">
                            <LandingPage/>
                        </Route>
                        <Route path="*">
                            <Box display='flex' justifyContent='center' alignItems='center' minHeight='30vh'>
                                <Alert variant="outlined" severity="error">Page not found</Alert>
                            </Box>
                        </Route>
                    </Switch>
                    <Footer/>
                </div>
            </Router>
        </MuiThemeProvider>
    );
}

const Footer = () => (
    <React.Fragment>
        <Grid item xs={12}><Box marginTop={30}>&nbsp;</Box></Grid>
        <Grid item xs={12}>
            <small>© Smotana. All rights reserved.</small>
        </Grid>
    </React.Fragment>
);
const LandingPage = () => {
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}><Box marginTop={30}>&nbsp;</Box></Grid>

            <Grid item xs={12} sm={6} md={3}></Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Box textAlign='left' className={classes.contentProject}>
                    <Typography variant="h4" component="h4">Projects</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}></Grid>

            {/*CLEARFLASK*/}
            <Grid item xs={12} sm={6} md={3}>
                <Box textAlign='left' marginLeft='auto' className={classes.contentProject}>
                    <Typography variant="h5" component="h3"
                                className={classes.projectHeading}><ClearFlaskLogo/></Typography>
                    <Typography variant="body1" component="div">Open-source Ideation Tool for Feedback, Roadmap and
                        Announcements</Typography>
                    <Box marginLeft='40%' marginTop={2} textAlign='center'>
                        <Button
                            style={{
                                textAlign: 'center',
                                color: '#218774',
                            }}
                            href='https://clearflask.com'
                            color='inherit'
                        >Open</Button>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Box className={classes.imgProjectContainer}>
                    {/* <img src='/img/clearflask.png' className={classes.imgProject} alt="ClearFlask" /> */}
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}></Grid>

            {/*DATASPRAY*/}
            <Grid item sm={6} md={3}>
                <Box className={classes.imgProjectContainer}>
                    {/* <img src='/img/depth.png' className={classes.imgProject} alt="Photogrammetry" /> */}
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Box textAlign='left' marginRight='auto' className={classes.contentProject}>
                    <img
                        className={classes.projectHeading}
                        src='/img/dataspray-logo.svg'
                        alt='DataSpray'
                        // width={32}
                        height={45}
                    />
                    {/*<GradientTypography color='primary' variant="h5" component="h3" className={classes.projectHeading}>DataSpray</GradientTypography>*/}
                    <Typography variant="body1" component="div">Open-source Stream Processing framework for fast
                        development,
                        historical analysis, and affordable per-event cloud pricing</Typography>
                    <Box marginLeft='40%' marginTop={2} textAlign='center'>
                        <Button
                            style={{
                                textAlign: 'center',
                                color: '#218774',
                            }}
                            href='https://dataspray.io'
                            color='inherit'
                        >Open</Button>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}></Grid>

            {/*QUARANTAB*/}
            <Grid item xs={12} sm={6} md={3}>
                <Box textAlign='left' marginLeft='auto' className={classes.contentProject}>
                    <img
                        className={classes.projectHeading}
                        src='/img/quarantab-logo.png'
                        alt='QuaranTab'
                        // width={32}
                        height={45}
                    />
                    {/*<GradientTypography color='primary' variant="h5" component="h3" className={classes.projectHeading}>DataSpray</GradientTypography>*/}
                    <Typography variant="body1" component="div">
                        Privacy tool for using sensitive data with online tools. Browser extension to cut-off network
                        access to a website to prevent leaking information.
                    </Typography>
                    <Box marginLeft='40%' marginTop={2} textAlign='center'>
                        <Button
                            style={{
                                textAlign: 'center',
                                color: '#218774',
                            }}
                            href='https://github.com/matusfaro/quarantab'
                            color='inherit'
                        >Open</Button>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}></Grid>

            <Grid item xs={12}><Box marginTop={30}>&nbsp;</Box></Grid>


            <Grid item xs={12} sm={6} md={8}></Grid>
            {/* <Grid item xs={12} sm={6} md={3}>
        <Box textAlign='left' className={classes.contentProject}>
          <Typography variant="h4" component="h4">Careers</Typography>
        </Box>
        <Box textAlign='left' marginTop={4}>
          {careers.map(career => (
            <Box marginTop={1}>
              <Typography variant="body1" component="div">
                <Link to={`/careers/${career.slug}`} className={classes.link}>
                  {career.title}
                </Link>
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid> */}

            <Grid item xs={12}><Box marginTop={30}>&nbsp;</Box></Grid>

            <Grid item xs={12} sm={6}>
                <Box className={classes.imgProjectContainer}>
                    <img src='/img/ub-2.jpg' className={classes.imgProject} style={{border: '1px solid black', marginTop: 64}}
                         alt='map'/>
                    <img src='/img/to-2.jpg' className={classes.imgProject} style={{border: '1px solid black', marginBottom: 64}}
                         alt='map'/>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} alignContent='center'>
                <Box textAlign='left' margin={4} marginRight='auto' maxWidth={150}>
                    <MuiLink className={classes.link} href="mailto:hello@smotana.com" color="inherit">
                        <Typography variant="h5" component="h2" display='inline'>Get</Typography>
                        <Typography variant="body1" component="div" display='inline'> in touch</Typography>
                    </MuiLink>
                </Box>
            </Grid>
        </Grid>
    );
};

const LandingHeader = () => {
    const classes = useStyles();
    return (
        <Box className={classes.centerVerticalContainer} height='100vh'>
            <Box className={classes.centerVerticalInner}>
                <img src='/img/logo.svg' className={classes.imgFit} alt="logo"/>
                <div className={classes.smotanaBrandName} style={{fontSize: 32}}>smotana</div>
            </Box>
            <ExpandDownIcon fontSize='inherit' className={classes.expandDownIcon}/>
        </Box>
    );
};

const Header = () => {
    const classes = useStyles();
    return (
        <Box>
            <Box marginBottom={5} display='flex' justifyContent='left'>
                <img src='/img/logo.svg' className={classes.imgFit} alt="logo" height='150px'/>
            </Box>
        </Box>
    );
};

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
